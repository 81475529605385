import React from "react";
import {
  BrowserRouter as Router,
  Route,
  Routes,
  useLocation,
} from "react-router-dom";
import ReactGA from "react-ga";
import Hero from "./components/Hero/Hero";
import Footer from "./components/Footer/Footer";
import Music from "./categories/Music/Music";
import Movies from "./categories/Movies/Movies";
import Soccer from "./categories/Soccer/Soccer";
import Games from "./categories/Games/Games";
import Contact from "./components/Contact/Contact";
import MainNavbar from "./components/MainNavbar/MainNavbar";
import Protected from "./Protected";
import AdminPanel from "./components/Login/AdminPanel/AdminPanel";
import Login from "./components/Login/Login";
import ThisOrThat from "./categories/ThisOrThat/ThisOrThat";
import Panel2 from "./components/Login/Panel2/Panel2";

const GA_TRACKING_ID = "G-GH0Q65HHQ4";
ReactGA.initialize(GA_TRACKING_ID);

function App() {
  return (
    <Router>
      <AppContent />
    </Router>
  );
}

const AppContent = () => {
  const location = useLocation();

  React.useEffect(() => {
    ReactGA.set({ page: location.pathname });
    ReactGA.send("pageview");
  }, [location]);

  return (
    <>
      {location.pathname === "/" && <MainNavbar />}
      <div className="container">
        <Routes>
          <Route path="/" element={<Hero />} />
          <Route path="/muzik" element={<Music />} />
          <Route path="/sinema" element={<Movies />} />
          <Route path="/futbol" element={<Soccer />} />
          <Route path="/oyun" element={<Games />} />
          <Route path="/omubumu" element={<ThisOrThat />} />
          <Route path="/iletisim" element={<Contact />} />
          <Route
            path="/secretlogin"
            element={<Protected Component={AdminPanel} />}
          />
          <Route
            path="/secretlogin2"
            element={<Protected Component={Panel2} />}
          />
          <Route path="/master" element={<Login />} />
        </Routes>
      </div>
      <Footer />
    </>
  );
};

export default App;
