import React from "react";
import "./MainNavbar.css";

const MainNavbar = () => {
  return (
    <nav className="mainNav-wrapper">
      <div className="mainNav-content">
        <a className="logo-item" href="/">
          <img
            className="logo"
            src="/assets/images/logoKuiz.png"
            alt="anasayfa"
          />
        </a>
        <div className="svg">
          <img
            src={"/assets/images/gameday.svg"}
            alt="Question Mark"
            className="svg-icon"
          />
        </div>
      </div>

    </nav>
  );
};

export default MainNavbar;
