import   {useEffect} from "react";
import { useNavigate } from "react-router-dom";

const Protected = (props) =>{
    const navig = useNavigate();
    const {Component} = props;
    useEffect(()=>{
        let login = localStorage.getItem("login");
        if(login !== "true"){
            localStorage.setItem("loginStatus", "Please login to view page!");
            navig("/",{replace: true});
        }

    },[]);
    return(
        <Component/>
    );

}

export default Protected;