import React, { useState } from "react";
import axios from "axios";
import MainNavbar from "../../components/MainNavbar/MainNavbar";
import "./Contact.css";

const Contact = () => {
  const [formData, setFormData] = useState({
    name: "",
    email: "",
    message: "",
  });

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData({
      ...formData,
      [name]: value,
    });
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    try {
      const response = await axios.post(
        "https://kuiz.fun/api/form.php",
        formData,
        {
          headers: {
            "Content-Type": "application/json",
          },
        }
      );
      console.log("Form submitted:", response.data);
      alert("Mesajınız gönderildi!");
      setFormData({
        name: "",
        email: "",
        message: "",
      });
    } catch (error) {
      console.error("There was an error submitting the form!", error);
    }
  };

  return (
    <>
      <MainNavbar />
      <div className="contact-page">
        <div className="contact-form-container">
          <form onSubmit={handleSubmit} className="contact-form">
            <h2>İletişim</h2>
            <label>
              İsim:
              <input
                type="text"
                name="name"
                value={formData.name}
                onChange={handleChange}
                required
              />
            </label>
            <label>
              Email:
              <input
                type="email"
                name="email"
                value={formData.email}
                onChange={handleChange}
                required
              />
            </label>
            <label>
              Mesaj:
              <textarea
                name="message"
                value={formData.message}
                onChange={handleChange}
                required
              ></textarea>
            </label>
            <button type="submit">Gönder</button>
          </form>
        </div>
      </div>
    </>
  );
};

export default Contact;
