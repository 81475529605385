import React from "react";
import { useNavigate } from "react-router-dom";
import "./Hero.css";

const Hero = () => {
  const navigate = useNavigate();

  const handleCategoryClick = (category) => {
    navigate(category);
  };

  return (
    <div className="hero-container">
      <div className="top-category">
        <div className="category">
          <img
            src="/assets/images/music-und.png"
            alt="Music"
            onClick={() => handleCategoryClick("/muzik")}
          />
          <span className="text">Müzik</span>
        </div>
        <div className="category">
          <img
            src="/assets/images/un-cinema.png"
            alt="Movies"
            onClick={() => handleCategoryClick("/sinema")}
          />
          <span className="text">Sinema</span>
        </div>

        <div className="category">
          <img
            src="/assets/images/checkBoxes.png"
            alt="This Or That"
            onClick={() => handleCategoryClick("/omubumu")}
          />
          <span className="text">O mu Bu mu?</span>
        </div>
      </div>

      <div className="bottom-category">
        <div className="category">
          <img
            src="/assets/images/soccer-und.png"
            alt="Soccer"
            onClick={() => handleCategoryClick("/futbol")}
          />
          <span className="text">Futbol</span>
        </div>
        <div className="category">
          <img
            src="/assets/images/gaming-und.png"
            alt="Game"
            onClick={() => handleCategoryClick("/oyun")}
          />
          <span className="text">Oyun</span>
        </div>
      </div>
    </div>
  );
};

export default Hero;
