import React, { useState } from "react";
import "./Navbar.css";
import "material-symbols";
import MobileNavbar from "./Mobile Navbar/MobileNavbar";

const Navbar = () => {
  const [openMenu, setOpenMenu] = useState(false);

  const changeable = () => {
    const handleChange = localStorage.getItem("isChangeable");
    if (handleChange === "false") {
      return window.confirm(
        "Kuiz bitmeden ayrılmak istediğinize emin misiniz? Cevaplarınız kaybolacaktır."
      );
    }
    return true;
  };

  const handleNavigation = (event) => {
    if (!changeable()) {
      event.preventDefault();
    } else {
      setOpenMenu(false);
    }
  };

  const toggleMenu = () => {
    setOpenMenu(!openMenu);
  };

  return (
    <>
      <MobileNavbar isOpen={openMenu} toggleMenu={toggleMenu} />

      <nav className="nav-wrapper">
        <div className="nav-content">
          <a className="logo-item" href="/">
            <img
              className="logo-navbar"
              href="anasayfa"
              src="/assets/images/logoKuiz.png"
              alt="anasayfa"
            />
          </a>
          <ul>
            <li>
              <a className="menu-item" href="muzik" onClick={handleNavigation}>
                <img src="/assets/images/earphone.svg" alt="muzik" />
                Müzik
              </a>
            </li>
            <li>
              <a className="menu-item" href="sinema" onClick={handleNavigation}>
                <img src="/assets/images/movie1.svg" alt="sinema" />
                Sinema
              </a>
            </li>
            <li>
              <a className="menu-item" href="futbol" onClick={handleNavigation}>
                <img src="/assets/images/trophy.svg" alt="futbol" />
                Futbol
              </a>
            </li>
            <li>
              <a className="menu-item" href="oyun" onClick={handleNavigation}>
                <img src="/assets/images/gamepad2.svg" alt="oyun" />
                Oyun
              </a>
            </li>
            <li>
              <a className="menu-item" href="omubumu" onClick={handleNavigation}>
                O mu Bu mu?
              </a>
            </li>
            <li>
              <a
                className="menu-item glow-text no-underline"
                href="https://kuiz.fun/blog/"
                onClick={handleNavigation}
                rel="noopener noreferrer"
              >
                Blog
              </a>
            </li>
          </ul>
          <button className="menu-btn" onClick={toggleMenu}>
            <span
              className={"material-symbols-outlined"}
              style={{ fontSize: "1.8rem" }}
            >
              {openMenu ? "close" : "menu"}
            </span>
          </button>
        </div>
      </nav>
    </>
  );
};

export default Navbar;
