import React, { useState } from "react";
import "./Panel2.css";
import axios from "axios";

function Panel2() {
  const [formData, setFormData] = useState({
    question: "",
    correct_img: "",
    correct_answer: "",
    wrong_img: "",
    wrong_answer: "",
    date: null,
  });

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData((prevData) => ({
      ...prevData,
      [name]: value,
    }));
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    console.log(formData);
    try {
      const response = await axios.post(
        "https://kuiz.fun/api/questionEntry.php",
        formData,
        {
          headers: {
            "Content-Type": "application/json",
          },
        }
      );
      console.log("Veriler işlendi!", response.data);
      setFormData({
        question: "",
        correct_img: "",
        correct_answer: "",
        wrong_img: "",
        wrong_answer: "",
        date: null,
      });
    } catch (error) {
      console.log("Veriler işlenirken bir sorun oluştu!", error);
    }
  };

  return (
    <div className="panel2-container">
      <div className="question-part">
        <form onSubmit={handleSubmit} className="db2-form">
          <label>
            <span>Soru:</span>
            <input
              type="text"
              name="question"
              value={formData.question}
              onChange={handleChange}
            />
          </label>
          <label>
            <span>Doğru görsel:</span>
            <input
              type="text"
              name="correct_img"
              value={formData.correct_img}
              onChange={handleChange}
            />
          </label>
          <label>
            <span>Doğru cevap:</span>
            <input
              type="text"
              name="correct_answer"
              value={formData.correct_answer}
              onChange={handleChange}
            />
          </label>
          <label>
            <span>Yanlış Görsel:</span>
            <input
              type="text"
              name="wrong_img"
              value={formData.wrong_img}
              onChange={handleChange}
            />
          </label>
          <label>
            <span>Yanlış Cevap:</span>
            <input
              type="text"
              name="wrong_answer"
              value={formData.wrong_answer}
              onChange={handleChange}
            />
          </label>
          <label>
            <span>Tarih:</span>
            <input
              type="date"
              name="date"
              value={formData.date}
              onChange={handleChange}
            />
          </label>
          <button type="submit">Gönder</button>
        </form>
      </div>
    </div>
  );
}

export default Panel2;
