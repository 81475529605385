import React from "react";
import "./Footer.css";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faInstagram,
  faTiktok,
  faYoutube,
  faFacebookF,
} from "@fortawesome/free-brands-svg-icons";

const Footer = () => {
  const scrollToTop = () => {
    window.scrollTo({ top: 0, behavior: 'smooth' });
  };

  return (
    <div className="footer">
      <div className="sb_footer section_padding">
        <div className="sb_footer-links">
    
          <div className="footer-media-icons">
            <a
              href="https://www.instagram.com/kuiz.fun/"
              target="_blank"
              rel="noopener noreferrer"
              className="footer-icons"
            >
              <FontAwesomeIcon
                icon={faInstagram}
                style={{ color: "#49caa4", marginRight: "10px" }}
                size="2x"
              />
            </a>
            <a
              href="https://www.tiktok.com/@kuizfun"
              target="_blank"
              rel="noopener noreferrer"
              className="footer-icons"
            >
              <FontAwesomeIcon
                icon={faTiktok}
                style={{ color: "#49caa4", marginRight: "10px" }}
                size="2x"
              />
            </a>
            <a
              href="https://www.youtube.com/@KuizFun"
              target="_blank"
              rel="noopener noreferrer"
              className="footer-icons"
            >
              <FontAwesomeIcon
                icon={faYoutube}
                style={{ color: "#49caa4", marginRight: "10px" }}
                size="2x"
              />
            </a>
            <a
              href="https://www.facebook.com/profile.php?id=61564324414061"
              target="_blank"
              rel="noopener noreferrer"
              className="footer-icons"
            >
              <FontAwesomeIcon
                icon={faFacebookF}
                style={{ color: "#49caa4", marginRight: "10px" }}
                size="2x"
              />
            </a>
          </div>
          <div className="footer-company-name">
            <p>Kuiz @ 2024</p>
          </div>
          <div className="sb_footer-links-div">
            <a className="footer-item" href="/" onClick={scrollToTop}>
              Anasayfa
            </a>
            <a
              className="footer-item"
              href="https://kuiz.fun/blog/"
              onClick={scrollToTop}
              rel="noopener noreferrer"
            >
              Blog
            </a>
            <a className="footer-item" href="/iletisim" onClick={scrollToTop}>
              İletişim
            </a>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Footer;
